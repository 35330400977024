@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap);
body {
  margin: 0;
  font-family: "Poppins !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: capitalize;
}

code {
  font-family: "Poppins", monospace;
}

.postPageIcons {
  font-size: 150px !important;
  cursor: pointer;
}

.genderIcons {
  font-size: 50px !important;
  cursor: pointer;
  color: rgb(4, 175, 136);
  font-family: "Poppins";
}

@media only screen and (min-width: 500px) {
  .genderIcons {
    font-size: 100px !important;
  }
}

.MuiToggleButton-label {
  display: block !important;
}

.deleteImage {
  background: rgb(4, 175, 136) !important;
}

.uploadPictureContainer {
  width: 100px !important;
  margin: 10px !important;
}

.uploadPicturesWrapper {
  margin-top: 30px;
}

.fileContainer {
  box-shadow: none !important;
}

.noMargin {
  padding: 16px 0px !important;
}

.testimonialLayer2:before {
  border-right: 119px solid #8396c8;
  width: 120px;
  right: 70px;
  height: 60px;
  bottom: -20px;
  content: "";
  z-index: 0;
  position: absolute;
  border-bottom: 60px solid transparent;
}

.testimonialLayer2Mid:before {
  border-right: 119px solid #4fb088;
  width: 120px;
  right: 70px;
  height: 60px;
  bottom: -20px;
  content: "";
  z-index: 0;
  position: absolute;
  border-bottom: 60px solid transparent;
}

.google-places-autocomplete__suggestion {
  font-size: 12px !important;
}

#react-google-places-autocomplete-input {
  /*   background-color: rgba(255, 255, 255, 0.15);
 */
  width: auto;
}

.drawerPaper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.drawerPaper::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.drawerPaper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.singleListing > .image-slider {
  max-width: 600px;
}

.google-places-autocomplete__input {
  border-radius: 5px;
}

.location-search-input {
  background-image: url(/images/ac.png);
  display: inline-block;
  width: 123px;
  height: 21px;
  background-repeat: no-repeat;
  background-position: 96px 0;
  vertical-align: middle;
}

.autocomplete-dropdown-container {
  position: absolute;
  color: black;
}

.searchBar {
  float: right;
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  color: red;
}

.MuiOutlinedInput-inputMultiline {
  height: 100% !important;
}

.google-places-autocomplete__input {
  padding: 12px 46px 12px 19px !important;
  margin-bottom: 0.28px !important;
}

.google-places-autocomplete__suggestion {
  cursor: pointer !important;
}

.google-places-autocomplete__suggestion:hover {
  background-color: lightgrey !important;
}

.enterLocality > div > input {
  padding: 18.5px 14px !important;
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid lightgrey !important;
}

@media only screen and (max-width: 400px) {
  .enterLocality > div > input {
    width: 100% !important;
  }
}
.enterLocality1 > div > input {
  padding: 18.5px 14px !important;
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid lightgrey !important;
}

@media only screen and (max-width: 400px) {
  .enterLocality1 > div > input {
    width: 100% !important;
  }
}

.MuiPagination-ul {
  -moz-box-pack: center;
       justify-content: center;
  padding: 30px 0px 20px 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cityContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cityContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

header {
  max-width: 100vw;
}

.noMargin1 {
  padding: 16px 0px !important;
  text-align: center;
}

.MuiCircularProgress-root {
  width: 100px !important;
  height: 100px !important;
}

.MuiBadge-root {
  width: 100%;
}

.rsc-header {
  background: rgb(30, 50, 85);
}

@media only screen and (max-width: 568px) {
  .rsc-header {
    margin-top: 73px;
  }
  .rsc-footer {
    margin-top: -83px;
  }
}

.enterLocality2 > div > input {
  padding: 18.5px 14px !important;
  width: 100% !important;
  box-shadow: none !important;
  border: 1px solid lightgrey !important;
}

@media only screen and (max-width: 400px) {
  .enterLocality2 > div > input {
    width: 100% !important;
  }
}

.rsc-cs {
  box-shadow: none !important;
}

.MuiToggleButton-root {
  padding: 15px !important;
  font-size: 1rem !important;
}

.MuiTab-wrapper {
  font-family: "Poppins";
}

.MuiButton-label {
  text-transform: capitalize;
  font-weight: 800;
  font-family: "Poppins";
}

.fileUploader {
  width: 100%;
}

.fileContainer {
  background: #fff;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 10px;
  padding: 20px 0;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  margin: 10px auto;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.fileContainer input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.fileContainer p {
  font-size: 12px;
  margin: 8px 0 4px;
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.fileContainer .chooseFileButton {
  padding: 6px 23px;
  background: #3f4257;
  border-radius: 30px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
}

.fileContainer .chooseFileButton:hover {
  background: #545972;
}

.fileContainer .uploadFilesButton {
  padding: 5px 43px;
  background: transparent;
  border-radius: 30px;
  color: #3f4257;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px solid #3f4257;
}

.fileContainer .uploadFilesButton:hover {
  background: #3f4257;
  color: #fff;
}

.fileContainer .uploadIcon {
  display: none;
}

.fileContainer .uploadPicturesWrapper {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-pack: center;
       justify-content: center;
  width: 100%;
}

.fileContainer .uploadPictureContainer {
  width: 25%;
  margin: 5%;
  padding: 10px;
  background: #edf2f6;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.fileContainer .uploadPictureContainer img.uploadPicture {
  width: 100%;
}

.fileContainer .deleteImage {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

.flipMove {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

